
.data-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .show-content {
        flex: 1;
        display: flex;
        flex-direction: row;
        //justify-content: flex-end;
        justify-content: center;

        .top-tips {
            margin-right: 20px;
        }

        .main-content {
            width: 400px;
            height: 460px;
            background: #0c0e3f;
            border-radius: 20px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            //flex: 1;

            .top-imgBox {
                width: 400px;
                height: 330px;
                position: relative;
                margin-left: -30px;
                margin-top: -30px;
                position: relative;
                margin-right: 100px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    //margin-left: -3px;
                }

                .footer-content {
                    position: absolute;
                    width: 400px;
                    bottom: 0;
                    background: #000000;
                    opacity: 0.6;
                    display: flex;
                    align-items: center;
                    padding: 25px;
                    color: #fff;
                    font-size: 16px;

                    i {
                        font-size: 24px;
                        margin-right: 13px;
                    }
                }
            }

            .bottom-box {
                padding: 20px;
                color: #fff;
                flex: 1;

                .bottom-title {
                    font-size: 18px;
                }

                .bottom-imgBox {
                    margin-top: 13px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                    }

                    .bottom-name {
                        margin-left: 10px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.el-form {
    ::v-deep .el-form-item {

        &.is-required {
            .el-form-item__label {
                position: relative;

                &::before {
                    position: absolute;
                    right: -15px;
                }
            }
        }
    }
}

.el-radio {
    display: flex;
    line-height: 16px;

    &.is-checked {
        ::v-deep .el-radio__inner {
            border: 3px solid #4b45ff;

            &::after {
                content: unset;
            }
        }

        ::v-deep .el-radio__label {
            color: #fff;
        }
    }
}

.el-radio + .el-radio {
    margin-top: 20px;
}

.label-tips {
    margin-left: 10px;
    opacity: 0.2;
    color: #fff;
}

.dark-input {
    width: 380px;
}

.dark-text {
    width: 800px;
}

.upload-content {
    display: flex;

    .upload-tips {
        //color: #fff;
        opacity: 0.2;
        display: flex;
        align-items: center;
        margin: 0 96px 0 20px;
    }

    .img-box {
        width: 100px;
        height: 100px;
        position: relative;

        .shadow-box {
            position: absolute;
            bottom: 0;
            background: #000000;
            opacity: 0.6;
            border-radius: 4px;
            width: 100px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
        }

        img {
            width: 100px;
            height: 100px;
        }
    }

    .img-uploader {
        width: 100px;
        height: 100px;
        border: 1px solid #DCDFE6;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-radius: 4px;

        .add-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .add-icon {
                color: #DCDFE6;
                font-size: 24px;
            }
        }
    }
}
